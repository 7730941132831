<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">FiqOn</h2>
        <b-img fluid class="fiqon-logo" :class="logoUrl ? '' : 'light-logo'" :src="logoImg" alt="Login V2" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col xl="8" lg="6" class="d-none d-lg-flex align-items-center p-5">

        <div class="
            dark-light-icon
            bookmark-wrapper
            align-items-center
            flex-grow-1
            d-none d-lg-flex
          ">
          <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        </div>
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <Animation animationType="login" />
        </div>

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col xl="4" lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5 ">
        <Locale class="locale" />
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div v-if="!isNewAdressAcess"
            v-bind:class="{ animate__animated: returningToLogin, animate__fadeInLeft: returningToLogin }">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              {{ t('login.welcome') }} <span class="brand-name">FiqOn</span>
              <b-img fluid class="fiqon-logo-welcome" :class="logoUrl ? '' : 'light-logo'" :src="logoImg"
                alt="Login Logo" />
            </b-card-title>
            <b-card-text class="mb-2">
              {{ t('login.login-data') }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2 " @submit.prevent>
                <!-- username -->
                <b-form-group :label="t('login.username')" label-for="register-username">
                  <validation-provider #default="{ errors }" :name="t('login.username-validator')" vid="username"
                    rules="required">
                    <b-form-input id="register-username" v-model="username" name="register-username"
                      :state="errors.length > 0 ? false : null" placeholder="Username" />
                    <small name="username" class="text-danger">{{ errors[ 0 ] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ t('login.password') }}</label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ t('login.forgot-password') }}</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" :name="t('login.password-validator')" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        placeholder="············" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[ 0 ] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox v-model="rememberMe" name="checkbox-1"
                    @change="(e) => { if (e == false) { removeRememberMe() } }">
                    {{ t('login.remember-me') }}
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group :class="highlightPolicyBtn ? 'outlined-red' : ''" tabindex="0">
                  <b-form-checkbox v-model="agreedToPolicies" @change="storePolicyAgreement()">
                    <div class="d-flex align-items-center ">
                      I agree to FiqOn's
                      <b-link class="p-0 text-success ml-25" to="politica-de-privacidade">
                        Privacy Policies
                      </b-link>
                    </div>
                  </b-form-checkbox>
                </b-form-group>

                <b-button type="submit" variant="primary" block @click="validationForm"
                  @blur="highlightPolicyBtn = false" :class="(loadingLogin || !agreedToPolicies) ? 'disable-btn' : ''">
                  <span v-if="!loadingLogin">{{ t('login.sign-in') }}</span>
                  <b-spinner small v-else />

                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <!-- <span>{{ t('login.new-user')}}</span> -->
              <!-- <b-link :to="{ name: 'register' }">
                <span>&nbsp;{{ t('login.create-account')}}</span>
              </b-link> -->
            </b-card-text>

            <!-- divider -->
            <div class="divider my-2">
              <b-link href="https://fique.online" target="_blank" class="divider-text"> fique.online </b-link>
            </div>
          </div>

          <!-- access email sent section -->
          <div v-else class="d-flex flex-sm-column animate__animated animate__fadeInRight">
            <b class="text-center">
              <big>
                A new address ip has been identified to this account:
              </big>
            </b><br />

            <smal class="m-auto text-primary">{{ this.userEmail }}</smal><br />

            <b class="text-center">
              <big>
                to enable your access
                <br>
                please verify your email inbox.
              </big>
            </b><br /><br />

            <b-button type="submit" variant="primary" block @click="returnToLogin">
              <span> Back to login</span>
            </b-button>

            <div class="divider my-2">
              <b-link href="https://fique.online" target="_blank" class="divider-text"> fique.online </b-link>
            </div>
          </div>
          <!-- end access email sent section -->

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <!-- 2FA Modal -->
    <b-modal ref="twoAuthModal" size="lg" centered title="Authenticate Your Access" hide-footer no-close-on-backdrop>
      <h3 class="text-left d-block text-wrap">
        Open your authentication app to scan the QR Code
        below and get your verification code.
      </h3>
      <div class="d-flex flex-column">
        <b-row class="d-flex justify-content-center align-items-center py-2">
          <div class="bg-white">
            <b-img width="200vh" :src="qrcode" alt="qrcode" />
          </div>
        </b-row>
        <b-row class="text-center ml-5 mr-5">
          <b-input-group class="input-group ml-5 mr-5">
            <b-form-input :state="invalidCode" autofocus :placeholder="`code`" class="form-control my-2" maxlength="6"
              v-model="authSecret" aria-describedby="input-live-feedback" />
          </b-input-group>
          <!--- Invalidate input -->
          <b-form-invalid-feedback v-if="!validCode" id="input-live-feedback" class="py-1 text-danger ml-5">
            This code is invalid.
          </b-form-invalid-feedback>

        </b-row>
        <div class="divider border-top py-1">
        </div>
      </div>
      <div class="d-flex flex-row justify-content-around">
        <div class="mr-3 justify-content-start">
          <span>
            <small>
              Having trouble to scanning the QR Code ? Insert this key manualy in your authenticator app
            </small>
          </span>
          <br />
          <small>
            <small class="text-primary text-break">
              {{ authCode }}
            </small>
          </small>
        </div>
        <div class="mr-2">
          <b-button variant="outline-danger" @click="closeModal()">Cancel</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="validateAuthCode()">Validate</b-button>
        </div>
      </div>

    </b-modal>

    <!-- 2FA Modal -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
  BModal,
  BInput,
} from "bootstrap-vue";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store/index";
import Animation from "/src/layouts/components/Animation.vue";
import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom";
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import PrivacyPolicyModal from "@/views/pages/login/privacyPolicyModal.vue";
import { axiosCall, apiCall } from "@/store/AxiosCaller";

import Locale from '@/layouts/components/Locale.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Animation,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    PrivacyPolicyModal,
    DarkToggler,
    Locale,
    BModal,
    BInput,
  },
  mixins: [ togglePasswordVisibility ],
  data() {
    const { t } = useI18nUtils()
    return {
      rememberMe: "",
      password: "",
      username: "",
      logoImg: require("@/assets/images/logo/logo.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      t,
      validationRefs: [ 'username', 'password' ],
      loadingLogin: false,
      agreedToPolicies: false,
      highlightPolicyBtn: false,
      isNewAdressAcess: false,
      userEmail: "",
      returningToLogin: false,
      qrcode: "",
      authSecret: null,
      authCode: null,
      validCode: true,
    };
  },

  mounted() {
    let rememberMe = localStorage.getItem('storedRememberMe')
    if (rememberMe) {
      this.username = rememberMe
      this.rememberMe = true
    }

    if (localStorage.getItem('hasAgreedToPolicies')) {
      this.agreedToPolicies = true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return false;
      }
      return true;
    },
    invalidCode() {
      if (this.validCode) {
        return null;
      }
      return false;
    }
  },
  methods: {
    storePolicyAgreement() {
      if (this.agreedToPolicies) {
        localStorage.setItem('hasAgreedToPolicies', true)
      } else {
        localStorage.removeItem('hasAgreedToPolicies')
      }
    },
    removeRememberMe() {
      localStorage.removeItem('storedRememberMe')
    },
    async validationForm() {
      if (this.loadingLogin) {
        return
      }
      if (!this.agreedToPolicies) {
        this.highlightPolicyBtn = true;
        return
      }
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          localStorage.setItem('storedRememberMe', this.username)
          this.loadingLogin = true
          this.$store
            .dispatch("loginVerification", {
              username: this.username,
              password: this.password,
            })
            .then((response) => {
              if (response.email_sent) {
                this.isNewAdressAcess = true;
                this.userEmail = response.email;
                return;
              }


              this.$store
                .dispatch("twoFactAuth", {}).then((resp) => {
                  if(resp.spectator == true){
                    this.$router.push({ name: "home" }).then(() => {
                      makeToast(custom.successMessages.home);
                    })
                    return;
                  }

                  if (resp.qr) {
                    this.qrcode = resp.qr;
                    this.authCode = resp.secret
                  } else if (localStorage.getItem("secretKey") || resp.alreadyExists == true) {
                    this.$router.push({ name: "home" }).then(() => {
                      makeToast(custom.successMessages.home);
                    })
                  }
                  
                  this.loadingLogin = true

                  this.$refs[ 'twoAuthModal' ].show();
                  return;
                });

              this.loadingLogin = false;


              // let userData = response.data.data.user;

              // this.updateAbility(userData.enum_access_level)

              // switch (true) {
              //   case !userData.email_verified: {
              //     this.$router.push({ name: "verify-email" }).then(() => {
              //       makeToast(custom.infoMessages.verify_email);
              //     });
              //     break;
              //   }
              //   case !userData.phone_verified: {
              //     this.$router.push({ name: "verify-phone" }).then(() => {
              //       makeToast(custom.infoMessages.verify_phone);
              //     });
              //     break;
              //   }
              //   default: {
              //     this.$router.push({ name: "home" }).then(() => {
              //       makeToast(custom.successMessages.home);
              //     });
              //   }
              // }
            })
            .catch((error) => {
              this.loadingLogin = false
              let errorType = error.unique_code;

              if (errorType == "002") {
                this.$router
                  .replace({ name: "access-point-validation" })
                  .then(() => {
                    makeToast(custom.errorMessages[ errorType ]);
                  });
              } else {
                makeToast({
                  title: this.$t("login.toast.login.error.title"),
                  text: this.$t("login.toast.login.error.message"),
                  variant: "danger",
                  icon: "AlertCircleIcon",
                });
              }
            });
          // }else{
          //   this.validationRefs.forEach(element => { 
          //     this.$refs[element].applyResult({
          //       errors: ["this is a backend error"], // array of string errors
          //       valid: false, // boolean state
          //       failedRules: {} // should be empty since this is a manual error.
          //     });
          //   }) 
        }
      });
    },
    updateAbility(accessLevel) {
      if (accessLevel.label != 'GOD') {
        this.$ability.update([
          {
            action: 'manage',
            subject: 'all',
          },
        ])
      } else {
        this.$ability.update([
          {
            action: 'read',
            subject: 'all',
          },
        ])
      }
    },

    returnToLogin() {
      this.isNewAdressAcess = false;
      this.loadingLogin = false;
      this.returningToLogin = true
    },

    validateAuthCode() {
      this.$store.dispatch("validateAuthCode", {
        authCode: this.authCode,
        authSecret: this.authSecret
      }).then((resp) => {
        this.$refs[ 'twoAuthModal' ].hide();
        localStorage.setItem("secretKey", this.authCode);
        this.$router.push({ name: "home" }).then(() => {
          makeToast(custom.successMessages.home);
        })
      }).catch((error) => {
        this.validCode = false;
        return;
      });
    },

    closeModal() {
      this.$refs[ 'twoAuthModal' ].hide();
      this.loadingLogin = false;
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.disable-btn {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.outlined-red {
  border-radius: 3px;
  outline: 1px solid $danger;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.fiqon-logo-welcome {
  height: 17px;
}

.brand-name {
  color: $primary;
}

.locale {
  position: absolute;
  margin-top: 12px;
  left: 0;
  top: 0;

  @media (max-width: 991px) {
    right: 0;
    left: unset;
  }
}
</style>